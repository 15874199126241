import axios from 'axios';
import { notification } from 'antd';

import { api } from '../api';
import { deleteObject, ref } from 'firebase/storage';
import { storage } from '../Show/QLBaiViet/AddBaiViet/config';

export const getAllNoiDungBaiViet = async () => {
    try {
        const res = await axios.get(api('/api/noiDungBaiViet/getAll'));
        return res.data;
    } catch (error) {
        return null;
    }
};
export const createNoiDungBaiViet = async (baiViet_id, title,content, image1, image2, ) => {
    try {
        if (!title || !content) {
            notification.error({
                message: 'Bạn đang điền thiếu nội dung',
            });
            return 0;
        }
        const res = await axios.post(api('/api/noiDungBaiViet/create'), {
            baiViet_id,
            title,
            content,
            image1,
            image2,
        });
        window.location.href = '/chinhSuaBaiViet?id=' + baiViet_id;
        if (res.status === 201) {
            notification.success({
                message: 'Tạo Bài Viết Thành Công',
            });
        }
    } catch (error) {
        notification.error({
            message: error.message,
        });
    }
};

export const updateNDBaiViet = async (id, title,content,image1, image2) => {
    try {
        if (!title || !content) {
            const oldImgRef1 = ref(storage, image1);
            await deleteObject(oldImgRef1);
            const oldImgRef2 = ref(storage, image2);
            await deleteObject(oldImgRef2);
            notification.error({
                message: 'Bạn đang điền thiếu nội dung',
            });
            return null;
        }
        const res = await axios.put(api('/api/noiDungBaiViet/update'), {
            id,
            title,
            content,
            image1,
            image2,
        });
        if (res.status === 200) {
            notification.success({
                message: 'Sửa Nội Dung Bài Viết Thành Công',
            });
        }
    } catch (error) {
        notification.error({
            message: error.message,
        });
    }
};
export const deleteNDBaiViet = async (id) => {
    try {
            await axios.delete(api('/api/noiDungBaiViet/deleteNDBaiViet'), { data: { id } });

            notification.success({
                message: 'Xóa Tất Cả Nội Dung Bài Viết Thành Công',
            });
            window.location.href = '/suabaiViet';

    } catch (error) {
        notification.error({
            message: "Xóa Nội Dung Bài Viết Thất Bại",
        });
        console.log(error);
    }
};
export const deleteOneNDBaiViet = async (id) => {
    try {
            await axios.delete(api('/api/noiDungBaiViet/delete'), { data: { id } });

            notification.success({
                message: 'Xóa Nội Dung Bài Viết Thành Công',
            });
            window.location.reload();

    } catch (error) {
        notification.error({
            message: "Xóa Nội Dung Bài Viết Thất Bại",
        });
        console.log(error);
    }
};