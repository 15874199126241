import classname from "classnames/bind";
import styles from "./BaiViet.module.scss";

import { Container, Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { getAllBaiViet } from "../../Action/BaiVietAction";
import moment from "moment";
import { Link } from "react-router-dom";

const cx = classname.bind(styles);
function SuaBaiViet() {
    const [baiViet, setBaiViet] = useState(null);

    useEffect(() => {
        getAllBaiViet()
            .then((DataBaiViet) => {
                if (DataBaiViet) {
                    setBaiViet(DataBaiViet);
                }
            });
        }, []);

        const formatUploadTime = (uploadTime) => {
            const now = moment();
            const uploadDate = moment(uploadTime);
        
            const diff = now.diff(uploadDate, 'days');
            
            if (diff === 0) {
              return 'Hôm nay';
            } else if (diff === 1) {
              return '1 ngày trước';
            } else if (diff >= 2 && diff <= 7) {
              return `${diff} ngày trước`;
            } else {
                return uploadDate.format('DD-MM-YYYY');
            }
          };

        let reversedArray;
    if(baiViet){
        reversedArray = Array.from(baiViet).reverse();
        }
    return ( 
        <div className={cx("qlbaiviet")}>
        <Container>
            <Row>
                {reversedArray && reversedArray.map((item, index) => (
                    <Col xl='3' xm='5' key={index}>
                    <Link to={`/chinhSuaBaiViet?id=${item._id}`}>
                        <div className={cx("item")}>
                            <img src={item.image} alt={item.title} className={cx("image")} />
                            <h5>{item.title}</h5>
                            {/* <p>{item.content}</p> */}
                            <p>{formatUploadTime(item.createdAt)}</p>
                        </div>
                    </Link>
                    </Col>
                ))}
            </Row>
        </Container>
    </div>
     );
}

export default SuaBaiViet;