import classname from "classnames/bind";
import styles from "./BaiViet.module.scss";
import { storage } from "../QLBaiViet/AddBaiViet/config";

import { Container, Col, Row, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { createBaiViet, getAllBaiViet, updateBaiViet } from "../../Action//BaiVietAction";
import { deleteObject, getDownloadURL, listAll, ref, uploadBytes } from "firebase/storage";
import {v4} from "uuid";
import { createNoiDungBaiViet, deleteOneNDBaiViet, updateNDBaiViet } from "../../Action/NoiDungBaiViet";
import { notification } from "antd";


const cx = classname.bind(styles);
function FormChinhSuaAll({NoiDungBaiViet}) {
    const [image1, setImage1] = useState("");
    const [image2, setImage2] = useState("");
    const [nDbaiViet] = useState(NoiDungBaiViet);
    const [title, setTitle] = useState(nDbaiViet.title);
    const [content, setContent] = useState(nDbaiViet.content);
    const [isSubmitting, setIsSubmitting] = useState(false);



    

    const handleChangeImage1 = (e) => {
        e.preventDefault();
        setImage1(e.target.files[0]);
    };
    const handleChangeImage2 = (e) => {
        e.preventDefault();
        setImage2(e.target.files[0]);
    };
    const handleChangeTitle = (e) => {
        e.preventDefault();
        setTitle(e.target.value);
    };
    const handleChangeContent = (e) => {
        e.preventDefault();
        setContent(e.target.value);
    };

    const submitNoiDung = async (img1, img2) => {
        if (isSubmitting) {
            notification.error({
                message: 'Reload lại trang để thực hiện lại chỉnh sửa đó',
            });
            return;
        }
        setIsSubmitting(true);
        let imgUrl1 = "";
        let imgUrl2 = "";
        
        if (image1) {
          const fileName1 = v4() + '.' + image1.name.split('.').pop(); 
          const imgref1 = ref(storage, `ImageOhyo/${fileName1}`);
          const snapshot1 = await uploadBytes(imgref1, image1);
          imgUrl1 = await getDownloadURL(snapshot1.ref);
        }
        
        if (image2) {
          const fileName2 = v4() + '.' + image2.name.split('.').pop(); 
          const imgref2 = ref(storage, `ImageOhyo/${fileName2}`);
          const snapshot2 = await uploadBytes(imgref2, image2);
          imgUrl2 = await getDownloadURL(snapshot2.ref);
        }
        
        try {
          if (imgUrl1 && imgUrl2) {
            const updatedBaiViet = await updateNDBaiViet(nDbaiViet._id, title, content, imgUrl1, imgUrl2);
            if(updatedBaiViet !== null){
            const oldImage1 = img1;
            const oldImage2 = img2;
                const oldImgRef1 = ref(storage, oldImage1);
                await deleteObject(oldImgRef1);
                const oldImgRef2 = ref(storage, oldImage2);
                await deleteObject(oldImgRef2);
            }

          } else if (imgUrl2) {
            const updatedBaiViet = await updateNDBaiViet(nDbaiViet._id, title, content, nDbaiViet.image1, imgUrl2);
            if(updatedBaiViet !== null){
            const oldImage2 = img2;
                const oldImgRef2 = ref(storage, oldImage2);
                await deleteObject(oldImgRef2);
            }
          } else if (imgUrl1) {
            const updatedBaiViet = await updateNDBaiViet(nDbaiViet._id, title, content, imgUrl1);
            const oldImage1 = img1;
            if(updatedBaiViet !== null){
                const oldImgRef1 = ref(storage, oldImage1);
                await deleteObject(oldImgRef1);
            }
          } else {
            updateNDBaiViet(nDbaiViet._id, title, content);
          }

          
        } catch (error) {
          console.error('Upload failed:', error);
        }
      }

      const delete1NDBaiViet = async (oldImg1, oldImg2) => {
        deleteOneNDBaiViet(nDbaiViet._id);
        const oldImage1 = oldImg1;
        const oldImage2 = oldImg2;
            const oldImgRef1 = ref(storage, oldImage1);
            await deleteObject(oldImgRef1);
            const oldImgRef2 = ref(storage, oldImage2);
            await deleteObject(oldImgRef2);
      }
    return ( 
        <div className={cx("AddNDBaiViet")}>
            <Container>
                <Row>
                    <div>
                        <h3>Chỉnh sửa nội dung cho bài viết</h3>
                        <h2>{title}</h2>
                        <Col className={cx("formPhu")}>
                            <div className="form-group">
                                <label htmlFor="title">Tiêu đề</label>
                                <input type="text" className="form-control" id="title" onChange={handleChangeTitle} value={title}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="content">Nội dung</label>
                                <textarea className="form-control" id="content" rows="3" onChange={handleChangeContent} value={content}></textarea>
                            </div>
                            <Row>
                                <Col xl="8">
                                    <div className={cx("uploadimg")}>
                                        <p htmlFor="image">Chọn hình thứ 1: </p>
                                        <input type="file" className="form-control-file" id="image" onChange={handleChangeImage1}/>
                                    </div>
                                </Col>
                                <Col xl='4'>
                                    <img className={cx("imgdemo")} src={image1 != ""?  URL.createObjectURL(image1) : nDbaiViet.image1} alt="Chưa có hình" />
                                </Col>
                            </Row>
                            <Row>
                                <Col xl="8">
                                    <div className={cx("uploadimg")}>
                                        <p htmlFor="image">Chọn hình thứ 2: </p>
                                        <input type="file" className="form-control-file" id="image" onChange={handleChangeImage2}/>
                                    </div>
                                </Col>
                                <Col xl='4'>
                                    <img className={cx("imgdemo")} src={image2 != ""?  URL.createObjectURL(image2) : nDbaiViet.image2} alt="Chưa có hình" />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button variant={'success'} onClick={() => submitNoiDung(nDbaiViet.image1, nDbaiViet.image2)} className="btn btn-primary">Hoàn Thành Sửa Nội Dung</Button>
                                </Col>
                                <Col>
                                    <Button variant={'danger'} onClick={() => {
                                        if (window.confirm('Bạn có chắc chắn muốn xóa nội dung này?')) {
                                            delete1NDBaiViet(nDbaiViet.image1, nDbaiViet.image2);
                                        }
                                    }}>Xóa nội dung này</Button>
                                </Col>
                            </Row>
                        </Col>
                    </div>
                </Row>
            </Container>
        </div>
    );
}

export default FormChinhSuaAll;
