import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./Show/Header/Header";
import "bootstrap/dist/css/bootstrap.min.css";
import "swiper/css";
import { publicRoutes } from "./routes/routes.js";
import Login from "./Show/Auth/Login.jsx";

function App() {
  const isLoggedIn = localStorage.getItem("login") === "true";

  return (
    <Router>
      <div className="App">
        <Header />
        {isLoggedIn ? (
          <Routes>
            {publicRoutes.map((route, index) => {
              const Page = route.component;

              return <Route key={index} path={route.path} element={<Page />} />;
            })}
          </Routes>
        ) : (
          <Login />
        )}
      </div>
    </Router>
  );
}

export default App;
