import classname from "classnames/bind";
import styles from "./AddBaiViet.module.scss";
import { storage } from "./config";

import { Container, Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { createBaiViet } from "../../../Action/BaiVietAction";
import { getDownloadURL, listAll, ref, uploadBytes } from "firebase/storage";
import {v4} from "uuid";


const cx = classname.bind(styles);
function AddBaiViet() {
    const [image, setImage] = useState("");
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");

    const handleChangeImage = (e) => {
        e.preventDefault();
        setImage(e.target.files[0]);
    };
    const handleChangeTitle = (e) => {
        e.preventDefault();
        setTitle(e.target.value);
    };
    const handleChangeContent = (e) => {
        e.preventDefault();
        setContent(e.target.value);
    };

    const submit = async () => {
        let imgUrl1 = "";
        let imgUrl2 = "";
        try {
            let imgUrl = "";
            if(image !== "") {
                const fileName = v4() + '.' + image.name.split('.').pop(); 
                const imgref = ref(storage, `ImageOhyo/${fileName}`);
                const snapshot = await uploadBytes(imgref, image);
                imgUrl = await getDownloadURL(snapshot.ref);
            }
            await createBaiViet(title, content, imgUrl);

        } catch (error) {
            console.log("Lỗi: " + error);
        }
    }


    return ( 
        <div className={cx("AddBaiViet")}>
            <Container>
                <Row>
                    <Col className={cx("formChinh")}>
                        <div className="form-group">
                            <label htmlFor="title">Tiêu đề chính</label>
                            <input type="text" className="form-control" id="title" onChange={handleChangeTitle}/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="content">Nội dung chính</label>
                            <textarea className="form-control" id="content" rows="3" onChange={handleChangeContent}></textarea>
                        </div>
                        <Row>
                            <Col xl="8">
                        <div className={cx("uploadimg")}>
                            <p htmlFor="image">Chọn hình Cho nội dung chính: </p>
                            <input type="file" className="form-control-file" id="image" onChange={handleChangeImage}/>
                            
                        </div>
                        </Col>
                        <Col xl='4'>
                        {!image && (
                                        <div className={cx("divdemo")}>Hình Demo</div>
                                    )}
                                {image && (
                                        <img className={cx("imgdemo")} src={URL.createObjectURL(image)} alt="Selected Image" />
                                    )}
                            </Col>
                        </Row>
                        
                    </Col>
                </Row>
                <button onClick={submit} className="btn btn-primary">Thêm Bài Viết</button>
            </Container>
        </div>
    );
}

export default AddBaiViet;
