import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCbbFKViBLZt0brAWucSUMOGSs3S8iCdak",
  authDomain: "kientrucohyo-f1c6c.firebaseapp.com",
  projectId: "kientrucohyo-f1c6c",
  storageBucket: "kientrucohyo-f1c6c.appspot.com",
  messagingSenderId: "256866013348",
  appId: "1:256866013348:web:caa5141dd82a28422ed631",
  measurementId: "G-TQTZSHDZ2C"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const storage = getStorage(app);