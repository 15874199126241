import classname from "classnames/bind";
import styles from "./BaiViet.module.scss";
import { storage } from "../QLBaiViet/AddBaiViet/config";

import { Container, Col, Row, Form, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import {deleteBaiViet, getBaiVietById, updateBaiViet } from "../../Action//BaiVietAction";
import { deleteObject, getDownloadURL, listAll, ref, uploadBytes } from "firebase/storage";
import {v4} from "uuid";

import FormChinhSuaNoiDung from "./FormChinhSuaNoiDung";
import { deleteNDBaiViet, getAllNoiDungBaiViet } from "../../Action/NoiDungBaiViet";
import { notification } from "antd";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const cx = classname.bind(styles);
function FormChinhSuaAll() {
    const [image, setImage] = useState("");
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [countNDBaiViet, setCountNDBaiViet] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [baiViet, setBaiViet] = useState(null);
    const [nDbaiViet, setNDBaiViet] = useState(null);
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');

    const [renderedNDBaiVietCount, setRenderedNDBaiVietCount] = useState(0);

    useEffect(() => {
        getBaiVietById(id)
            .then((DataBaiViet) => {
                if (DataBaiViet) {
                    setBaiViet(DataBaiViet);
                }
            });
    }, []);

    useEffect(() => {
        getAllNoiDungBaiViet()
            .then((DataNDBaiViet) => {
                if (DataNDBaiViet) {
                    setNDBaiViet(DataNDBaiViet);
                }
            });
    }, []);

    useEffect(() => {
        if (nDbaiViet && Array.isArray(nDbaiViet)) {
            const renderedCount = nDbaiViet.filter(item => item.baiViet_id === id).length;
            setRenderedNDBaiVietCount(renderedCount);
        }
    }, [nDbaiViet, id]);

    useEffect(() => {
        if (renderedNDBaiVietCount > 0) {
            setCountNDBaiViet(false);
        }
    }, [renderedNDBaiVietCount]);

    const handleChangeImage = (e) => {
        e.preventDefault();
        setImage(e.target.files[0]);
    };
    const handleChangeTitle = (e) => {
        e.preventDefault();
        setTitle(e.target.value);
    };
    const handleChangeContent = (e) => {
        e.preventDefault();
        setContent(e.target.value);
    };

    const submit = async (oldimg) => {
        if (isSubmitting) {
            notification.error({
                message: 'Reload lại trang để thực hiện lại chỉnh sửa',
            });
            return;
        }
        setIsSubmitting(true);
        try {
            let imgUrl = "";
            if (image !== "") {
                const fileName = v4() + "." + image.name.split(".").pop();
                const imgref = ref(storage, `ImageOhyo/${fileName}`);
                const snapshot = await uploadBytes(imgref, image);
                imgUrl = await getDownloadURL(snapshot.ref);
            }
    
            if (imgUrl !== "") {
                if (baiViet) {
                    const updatedBaiViet = await updateBaiViet(id, title, content, imgUrl);
                    if (updatedBaiViet !== null) {
                        const oldImage = oldimg;
                        if (oldImage) {
                            const oldImgRef = ref(storage, oldImage);
                            await deleteObject(oldImgRef);
                        }
                    }
                }

            } else {
                updateBaiViet(id, title, content);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const deleteBaiViets = async (oldimg) => {
        try {
            if (countNDBaiViet === true) {
                await deleteBaiViet(id);
                const oldImage = oldimg;
                if (oldImage) {
                    const oldImgRef = ref(storage, oldImage);
                    await deleteObject(oldImgRef);
                }
            } else {
                notification.error({
                    message: `Không thể xóa bài viết vì còn dữ liệu nội dung bài viết! ㅤㅤㅤㅤㅤㅤㅤㅤㅤHãy xóa hết dữ liệu nội dung bài viết trước!`,
                });
            }
        } catch (error) {
            console.log(error);
        }
    }
    console.log(countNDBaiViet);


    return ( 
        <div className={cx("AddBaiViet")}>
            <div className={cx("Help1")}>Viền Màu <span style={{color: 'blue'}}>xanh</span> là chỉnh sửa Nội dung chính của bài viết</div>
            <div className={cx("Help2")}>Viền Màu <span style={{color: 'chartreuse'}}>xanh</span> là chỉnh sửa Nội dung phụ của bài viết</div>
            <Container>
            <Row className={cx("return")}>
                        <Col>
                            <Link to={"/suaBaiViet"}><Button variant="primary">Quay Lại</Button></Link>
                        </Col>
                    </Row>
                <Row>
                {baiViet &&
                        <div>
                        <h3>Chỉnh sửa bài viết</h3>
                        <h2>{baiViet.title}</h2>
                        <Col className={cx("formChinh")}>
                        <div className="form-group">
                            <label htmlFor="title">Tiêu đề chính</label>
                            <input type="text" className="form-control" id="title" onChange={handleChangeTitle} value={title || baiViet.title}/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="content">Nội dung chính</label>
                            <textarea className="form-control" id="content" rows="3" onChange={handleChangeContent} value={content || baiViet.content}></textarea>
                        </div>
                        <Row>
                            <Col xl="8">
                        <div className={cx("uploadimg")}>
                            <p htmlFor="image">Chọn hình Cho nội dung chính: </p>
                            <input type="file" className="form-control-file" id="image" onChange={handleChangeImage}/>
                            
                        </div>
                        </Col>
                        <Col xl='4'>
                        <img className={cx("imgdemo")} src={image != ""?  URL.createObjectURL(image) : baiViet.image} alt="Selected Image" />
                                    
                            </Col>
                        </Row>
                        
                    <button onClick={() => submit(baiViet.image)} className="btn btn-primary">Hoàn Thành Sửa Nội Dung Chính</button>
                    </Col>
                        </div>
                    
                }
                </Row>

                <Row>
                {nDbaiViet && Array.isArray(nDbaiViet) ? (
                    nDbaiViet.map((item, index) => item.baiViet_id === id &&(
                        <FormChinhSuaNoiDung key={index} NoiDungBaiViet={item} />
                    
                    ))
                    ) : (
                    <p>Không có dữ liệu nội dung bài viết.</p>
                    )}
                </Row>

                    <Row>
                        <Col className={cx("Thembaiviet")}>
                        <Link to={"/themNoiDungBaiViet?id=" + id}>
                            <div >
                                <FontAwesomeIcon icon={faPlus} className={cx("icon")} />
                            </div>
                        </Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button variant={'danger'} onClick={() => {
                                        if (window.confirm('Bạn có chắc chắn muốn xóa bài viết này?')) {
                                            deleteBaiViets(baiViet.image);
                                        }
                                    }}>Xóa Bài Viết</Button>
                        </Col>
                    </Row>
            </Container>
        </div>
    );
}

export default FormChinhSuaAll;
