import { notification } from "antd";

export const loginAdmin = async (userName, password) => {
    try {
        if (!userName || !password) {
            notification.error({
                message: 'Bạn đang điền thiếu nội dung',
            });
            localStorage.setItem('login', "false");
            return;
        }else if(userName !== "kientrucohyo" || password !== "chuong1998nhi"){
            notification.error({
                message: 'Sai tài khoản hoặc mật khẩu',
            });
            localStorage.setItem('login', "false");
            return;
        }else{
            notification.success({
                message: 'Đăng Nhập Thành Công',
            });
            localStorage.setItem('login', "true");
            window.location.href = '/';
        }



    } catch (error) {
        notification.error({
            message: "Đăng Nhập Thất Bại",
        });
    }
};