import classname from "classnames/bind";
import styles from "./AddBaiViet.module.scss";
import { storage } from "./config";

import { Container, Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { createBaiViet, getAllBaiViet } from "../../../Action/BaiVietAction";
import { getDownloadURL, listAll, ref, uploadBytes } from "firebase/storage";
import {v4} from "uuid";
import { createNoiDungBaiViet } from "../../../Action/NoiDungBaiViet";

const cx = classname.bind(styles);
function AddNoiDungBaiViet() {
    const [image1, setImage1] = useState("");
    const [image2, setImage2] = useState("");
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");

    const [baiViet, setBaiViet] = useState(null);

    useEffect(() => {
        getAllBaiViet()
            .then((DataBaiViet) => {
                if (DataBaiViet) {
                    setBaiViet(DataBaiViet);
                }
            });
        }, []);

    const handleChangeImage1 = (e) => {
        e.preventDefault();
        setImage1(e.target.files[0]);
    };
    const handleChangeImage2 = (e) => {
        e.preventDefault();
        setImage2(e.target.files[0]);
    };
    const handleChangeTitle = (e) => {
        e.preventDefault();
        setTitle(e.target.value);
    };
    const handleChangeContent = (e) => {
        e.preventDefault();
        setContent(e.target.value);
    };
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');

    const submitNoiDung = async () => {
        let imgUrl1 = "";
        let imgUrl2 = "";

        if (image1) {
          const fileName1 = v4() + '.' + image1.name.split('.').pop(); 
          const imgref1 = ref(storage, `ImageOhyo/${fileName1}`);
          const snapshot1 = await uploadBytes(imgref1, image1);
          imgUrl1 = await getDownloadURL(snapshot1.ref);
        }
        
        if (image2) {
          const fileName2 = v4() + '.' + image2.name.split('.').pop(); 
          const imgref2 = ref(storage, `ImageOhyo/${fileName2}`);
          const snapshot2 = await uploadBytes(imgref2, image2);
          imgUrl2 = await getDownloadURL(snapshot2.ref);
        }
        
        try {
            if(imgUrl1 && imgUrl2){
            createNoiDungBaiViet(id ,title,content, imgUrl1,imgUrl2);
        }else if(imgUrl1 && !imgUrl2){
            createNoiDungBaiViet(id ,title,content, imgUrl1);
        } else if(!imgUrl1 && imgUrl2){
            createNoiDungBaiViet(id ,title,content, null,imgUrl2);
        }else{
            createNoiDungBaiViet(id ,title,content);
        }
        } catch (error) {
          console.error('Upload failed:', error);
        }
    }


    return ( 
        <Container>
                {baiViet && baiViet.map((item, index) => (
                    item._id === id && (
                        <div>
                        <h3>Thêm nội dung cho bài viết</h3>
                        <h2>{item.title}</h2>
                        </div>
                    )
                ))}
                        <Row>
                            <Col className={cx("formChinh")}>
                                <div className="form-group">
                                    <label htmlFor="title">Tiêu đề cho nội dung</label>
                                    <input type="text" className="form-control" id="title" onChange={handleChangeTitle}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="content">Nội dung</label>
                                    <textarea className="form-control" id="content" rows="3" onChange={handleChangeContent}></textarea>
                                </div>
                                <Row>
                                    <Col xl="8">
                                        <div className={cx("uploadimg")}>
                                            <p htmlFor="image">Chọn hình thứ 1: </p>
                                            <input type="file" className="form-control-file" id="image" onChange={handleChangeImage1}/>
                                        </div>
                                    </Col>
                                    <Col xl='4'>
                                        {!image1 && (
                                            <div className={cx("divdemo")}>Hình Demo</div>
                                        )}
                                        {image1 && (
                                            <img className={cx("imgdemo")} src={URL.createObjectURL(image1)} alt="Selected Image" />
                                        )}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl="8">
                                        <div className={cx("uploadimg")}>
                                            <p htmlFor="image">Chọn hình thứ 2: </p>
                                            <input type="file" className="form-control-file" id="image" onChange={handleChangeImage2}/>
                                        </div>
                                    </Col>
                                    <Col xl='4'>
                                        {!image2 && (
                                            <div className={cx("divdemo")}>Hình Demo</div>
                                        )}
                                        {image2 && (
                                            <img className={cx("imgdemo")} src={URL.createObjectURL(image2)} alt="Selected Image" />
                                        )}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <button onClick={submitNoiDung} className="btn btn-primary">Submit</button>

    </Container>
     );
}

export default AddNoiDungBaiViet;