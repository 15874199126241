import axios from 'axios';
import { notification } from 'antd';

import { api } from '../api';
import { storage } from '../Show/QLBaiViet/AddBaiViet/config';
import { deleteObject, ref } from 'firebase/storage';

export const getAllBaiViet = async () => {
    try {
        const res = await axios.get(api('/api/baiviet/getAll'));
        return res.data;
    } catch (error) {
        return null;
    }
};

export const getBaiVietById = async (id) => {
    try {
        const res = await axios.get(api('/api/baiviet/getBaiVietById?id=' + id));
        return res.data;
    } catch (error) {
        notification.error({
            message: error.message,
        });
        console.log("Lỗi: " + error);
        return null;
    }
};
export const createBaiViet = async (title, content, image) => {
    try {
        if (!title || !content || !image) {
            notification.error({
                message: 'Bạn đang điền thiếu nội dung',
            });
            return;
        }
        const res = await axios.post(api('/api/baiviet/create'), {
            title,
            content,
            image,
        });
        window.location.href = '/qlbaiviet';
        if (res.status === 200) {
            notification.success({
                message: 'Tạo Bài Viết Thành Công',
            });
        }
    } catch (error) {
        notification.error({
            message: error.message,
        });
    }
};
export const updateBaiViet = async (id, title, content, image) => {
    try {
        if (!title || !content) {
            const oldImgRef = ref(storage, image);
            await deleteObject(oldImgRef);
            notification.error({
                message: 'Bạn đang điền thiếu nội dung',
            });
            return null;
        }
        const res = await axios.put(api('/api/baiviet/update'), {
            id,
            title,
            content,
            image,
        });
        if (res.status === 200) {
            notification.success({
                message: 'Sửa Bài Viết Thành Công',
            });
        }
    } catch (error) {
        notification.error({
            message: "Sửa Bài Viết Thất Bại",
        });
        return null;
    }
};
export const deleteBaiViet = async (id) => {
    try {
        const res = await axios.delete(api('/api/baiviet/delete?id=' + id));

            notification.success({
                message: 'Xóa Bài Viết Thành Công',
            });
            window.location.href = '/suabaiViet';

    } catch (error) {
        notification.error({
            message: "Xóa Bài Viết Thất Bại",
        });
    }
};
export const getBaiVietByAlt = async (alt) => {
    try {
        const res = await axios.get(api('/api/baiviet/getBaiVietByAlt'), { params: { alt } });
        return res.data;
    } catch (error) {
        console.log("Lỗi: " + error);
        return null;
    }
};
