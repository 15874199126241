import classname from "classnames/bind";
import styles from "./Login.module.scss";
import { Button, Form } from "react-bootstrap";
import { useState } from "react";
import { loginAdmin } from "../../Action/AuthAction";

const cx = classname.bind(styles);
function Login() {
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");

    const handleChangeUserName = (e) => {
        e.preventDefault();
        setUserName(e.target.value);
    };
    const handleChangePasword = (e) => {
        e.preventDefault();
        setPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        await loginAdmin(userName, password);

    }
        return (
            <div className={cx("login-form")}>
                <h2>Đăng nhập</h2>
                <div className={cx("username")}>
                <Form.Label htmlFor="inputPassword5">User Name</Form.Label>
                    <Form.Control
                        type="text"
                        id="inputPassword5"
                        aria-describedby="passwordHelpBlock"
                        onChange={handleChangeUserName}
                    />
                    </div>
                    <div className={cx("password")}>
                <Form.Label htmlFor="inputPassword5">Password</Form.Label>
                    <Form.Control
                        type="text"
                        id="inputPassword5"
                        aria-describedby="passwordHelpBlock"
                        onChange={handleChangePasword}
                    />
                    </div>

                        <Button className={cx("submit")} onClick={handleSubmit} >Đăng Nhập</Button>

            </div>
        );
    }
    
export default Login;
