import classname from "classnames/bind";
import styles from "./QLBaiViet.module.scss";
import { useEffect, useState } from "react";
import { getAllBaiViet } from "../../Action/BaiVietAction";
import { Container, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';
import { Link } from "react-router-dom";



const cx = classname.bind(styles);
function QLBaiViet() {

    const [baiViet, setBaiViet] = useState(null);

    useEffect(() => {
        getAllBaiViet()
            .then((DataBaiViet) => {
                if (DataBaiViet) {
                    setBaiViet(DataBaiViet);
                }
            });
        }, []);

        const formatUploadTime = (uploadTime) => {
            const now = moment();
            const uploadDate = moment(uploadTime);
        
            const diff = now.diff(uploadDate, 'days');
            
            if (diff === 0) {
              return 'Hôm nay';
            } else if (diff === 1) {
              return '1 ngày trước';
            } else if (diff >= 2 && diff <= 7) {
              return `${diff} ngày trước`;
            } else {
                return uploadDate.format('DD-MM-YYYY');
            }
          };

        let reversedArray;
    if(baiViet){
        reversedArray = Array.from(baiViet).reverse();
        }
    return ( 
        <div className={cx("qlbaiviet")}>
            <div className={cx("Help1")}>Bấm vào dấu + để thêm bài viết mới</div>
            <div className={cx("Help2")}>Bấm vào 1 bài viết để thêm nội dung cho bài viết đó</div>
            <Container>
                <Row>
                    <Col xl='2' xm='3'>
                        <Link to={"/addbaiviet"}>
                            <div className={cx("Thembaiviet")}>
                                <FontAwesomeIcon icon={faPlus} className={cx("icon")} />
                            </div>
                        </Link>
                    </Col>
                    {reversedArray && reversedArray.map((item, index) => (
                        <Col xl='3' xm='5' key={index}>
                        <Link to={`/themNoiDungBaiViet?id=${item._id}`}>
                            <div className={cx("item")}>
                                <img src={item.image} alt={item.title} className={cx("image")} />
                                <h5>{item.title}</h5>
                                {/* <p>{item.content}</p> */}
                                <p>{formatUploadTime(item.createdAt)}</p>
                            </div>
                        </Link>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
     );
}

export default QLBaiViet;